<template>
  <van-cell-group title="充值中心"></van-cell-group>
  <van-grid :column-num="2">
    <template v-for="item in deposits" :key="item">
      <van-grid-item @click="onConfirm(item)">
        <p>&yen;{{ item.depositAmount }}</p>
        <p style="color:#666;margin:5px 0 0 0;" v-if="item.depositGiftAmount > 0">送&yen;{{ item.depositGiftAmount }}</p>
      </van-grid-item>
    </template>
  </van-grid>
  <p style="margin:15px;">
    <small>提醒：充值金额无法退款</small>
  </p>
  <van-action-sheet v-model:show="payment.show" :actions="payment.actions" @select="onSelect" />
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'
// import { recharge, getPay } from '@/api/order'
import Cookies from 'js-cookie'
// import { useRouter } from 'vue-router'
import { Toast } from 'vant'
import { callWeixinPay } from '@/util/util'
import { post } from '@/util/request'

export default {
  components: {
  },
  setup () {
    // const router = useRouter()
    const state = reactive({
      customer: {},
      balance: 0,
      deposits: [],
      orderIds: [],
      member: {},
      payment: {
        show: false,
        actions: [
          {
            key: 'WEIXIN',
            name: '微信支付'
          }
          // {
          //   key: 'ZHIFUBAO',
          //   name: '支付宝支付'
          // }
        ]
      },
      params: {},
      buyerId: Cookies.get('buyer_id'),
      storeId: Cookies.get('store_id'),
      openid: Cookies.get('weixin_openid')
    })
    const onConfirm = (row) => {
      state.payment.show = true
      state.params = {
        amount: row.rechargeAmount,
        memberId: state.member.memberId
      }
    }
    const onSelect = (item) => {
      state.payment.show = false
      Toast.loading({ duration: 0, forbidClick: true })
      post('/order.createDeposit', {
        ...state.params,
        openid: state.openid,
        paymentMethod: item.key
      }).then(res => {
        Toast.clear()
        if (res.code === 0) {
          callWeixinPay(res.data.weixinPayParams).then(res => {
            if (res.result) {
              Toast.success('支付成功')
            } else {
              Toast.fail('支付取消')
            }
          })
        } else {
          Toast(res.msg)
        }
      })
    }
    const onSelectAmount = () => {
    }
    /*
    const callPay = () => {
      Toast.loading({ duration: 0, forbidClick: true })
      getPay({
        orderIds: state.orderIds,
        channel: 'WEIXIN'
      }).then(res => {
        Toast.clear()
        if (res.code === 0) {
          callWeixinPay(res.data).then(res => {
            if (res.result) {
              Toast.success('支付成功')
              init()
            } else {
              Toast.fail('支付取消')
            }
          })
        } else {
          Toast(res.msg)
        }
      })
    }
    */
    const init = () => {
      post('/product.listProduct', {
        scope: 'DEPOSIT',
        storeId: state.storeId
      }).then(res => {
        state.deposits = res.data.content
      })
      // getMember({
      //   buyerId: state.buyerId,
      //   storeId: state.storeId
      // }).then(res => {
      //   if (res.code === 0) {
      //     state.member = res.data
      //   } else {
      //     Toast(res.msg)
      //   }
      // })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onConfirm,
      onSelectAmount,
      onSelect
    }
  }
}
</script>

<style scoped>
</style>
